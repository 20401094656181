<template>
	<div class="app-main__inner">
		<div class="container rounded dm-sans-font">
			<div class="flex flex-col md:flex-row justify-between align-items-center mb-3">
				<div>
					<div class="flex justify-between align-items-center space-x-3 mb-3">
						<div class="filtering-above-table unset-pad">
							<div class="fl-pagetype">
								<div class="cursor-pointer hidden md:block" @click="switchFilter"><i
									:class="!grid ? 'bg-blue-900 text-white' : ''"
									class="iconly-brokenFilter font-medium"></i></div>
								<div class="cursor-pointer" @click="switchGrid"><i
									:class="grid ? 'bg-blue-900 text-white' : ''"
									class="iconly-brokenCategory font-medium"></i></div>
							</div>
						</div>
						<span class="text-sm md:text-2xl font-semibold text-gray-900">{{ $t('supplier') }}</span>
					</div>
				</div>
				<div class="flex flex-col md:flex-row justify-between align-items-center mb-3 space-x-3">
                    <span>
                        <input type="text" v-if="tab === 1" @input="searchConnectedCompanies" v-model="connectedSearch"
							   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 h-10 rounded-md text-gray-500"
							   v-bind:placeholder="$t('search')">
						<input type="text" v-if="tab === 2" @input="searchNonConnectedCompanies"
							   v-model="nonConnectSearch"
							   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 h-10 rounded-md text-gray-500"
							   v-bind:placeholder="$t('search')">
                    </span>
					<div
						class="flex justify-between align-items-center uppercase border rounded-md bg-white text-xs md:text-xl">
						<span @click="openTab(1)"
							  class="text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2 rounded-l-md"
							  :class="tab === 1 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
								$t('connectedSupplier')
							}}</span>
						<span @click="openTab(2)"
							  class="text-xs h-10  flex justify-center align-items-center cursor-pointer px-3 py-2 rounded-r-md"
							  :class="tab === 2 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
								$t('nonConnectedSupplier')
							}}</span>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
					<p class="text-base font-semibold">{{ $t('supplierInfoText') }}</p>
				</div>
			</div>
			<div class="row mt-3" v-if="loading">
				<div class="col-md-4" v-for="i in 12" :key="i">
					<div
						class="h-32 flex justify-start align-items-center w-full animate-pulse bg-gray-200 rounded mb-2">
						<p class="relative h-20 w-20 bg-gray-300 rounded-full ml-3"></p>
					</div>
				</div>
			</div>

			<div v-if="tab === 1" class="mt-3">
				<div class="row" v-if="grid">
					<div class="col-md-4 mt-3 mb-3" v-for="shop in GET_CONNECTED_SHOP_LIST" :key="shop.id">
						<a href="javascript:void(0)" v-on:click="setCurrentCompany(shop)"
						   class="shadow-sm rounded-md store-dashboard-link hover:no-underline border"
						>
							<div class="flex justify-between p-3">
                                <span class="flex-none mr-3">
                                    <img class="h-20" :src="shop.company_logo" alt="company logo"
										 v-if="shop.company_logo">
									<!-- <img class="h-20 border" src="./../../assets/images/preview/dummy.png" alt="company default logo" v-else> -->
                                </span>
								<span class="flex-1 flex flex-col">
                                    <span class="text-sm font-semibold">{{ shop.name | capitalizeEachString }}</span>
                                    <span class="text-gray-500">
                                        <span v-if="shop.store_description">{{ shop.store_description }}</span>
                                        <span class="text-gray-400" v-else><i>{{ $t('noDescription') }}</i></span>
                                    </span>
                                </span>
							</div>
						</a>
					</div>
				</div>
				<div class="row" v-else>
					<div class="col-md-12">
						<div class="table-responsive rounded-lg bg-white border">
							<table v-loading='loading' style="width: 100%;" id="exampledestroy"
								   class="table table-hover">
								<thead>
								<tr>
									<th class="text-xs">{{ $t('companyLogo') }}</th>
									<th class="text-xs">{{ $t('storeName') }}</th>
									<th class="text-xs">{{ $t('description') }}</th>
									<th class="text-xs"></th>
								</tr>
								</thead>
								<tbody v-for="shop in GET_CONNECTED_SHOP_LIST" :key="shop.id">
								<!--v-for="shop in GET_CONNECTED_SHOP_LIST" :key="shop.id"-->
								<tr>
									<td>
                                        <span class="h-20 w-20 rounded-full">
                                            <img class="h-20" :src="shop.company_logo" alt="company logo"
												 v-if="shop.company_logo">
											<!-- <img class="h-20 border" src="./../../assets/images/preview/dummy.png" alt="company default logo" v-else> -->
                                        </span>
									</td>
									<td><span>{{ shop.name }}</span></td>
									<td>
										<span v-if="shop.store_description">{{ shop.store_description }}</span>
										<span class="text-gray-400" v-else><i>{{ $t('noDescription') }}</i></span>
									</td>
									<td>
										<a href="javascript:void(0)" v-on:click="setCurrentCompany(shop)"
										   class="no-deco px-4 py-2 bg-white border-2 font-semibold border-yellow-500 text-yellow-500 rounded-sm cursor-pointer whitespace-nowrap hover:text-yellow-500">
											{{ $t('viewDetails') }}
										</a>
									</td>
								</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div v-else class="mt-3">
				<div class="row">
					<div class="col-md-12">
						<div class="non-connected table-responsive rounded-lg bg-white border">
							<table v-loading='loading' style="width: 100%;" id="exampledestroy"
								   class="table table-hover">
								<thead>
								<tr>
									<th class="text-xs">{{ $t('companyLogo') }}</th>
									<th class="text-xs">{{ $t('storeName') }}</th>
									<th class="text-xs">{{ $t('description') }}</th>
									<th class="text-xs"></th>
								</tr>
								</thead>
								<tbody v-for="shop in GET_NON_CONNECTED_SHOP_LIST" :key="shop.id">
								<!--v-for="shop in GET_CONNECTED_SHOP_LIST" :key="shop.id"-->
								<tr>
									<td class="text-center ">
                                        <div class="h-[70px] w-[70px] border rounded-full relative bg-white mx-auto">
                                            <img class="h-[50px] w-[50px] absolute top-[10px] left-[8px]" :src="shop.company_logo" alt="company logo"
												 v-if="shop.company_logo">
											<!-- <img class="h-20 border" src="./../../assets/images/preview/dummy.png" alt="company default logo" v-else> -->
                                        </div>
									</td>
									<td><span>{{ shop.name }}</span></td>
									<td>
										<span v-if="shop.store_description">{{ shop.store_description }}</span>
										<span class="text-gray-400" v-else><i>{{ $t('noDescription') }}</i></span>
									</td>
									<td>
										<span
											class="no-deco px-4 py-2 bg-white border-2 font-semibold border-yellow-500 text-yellow-500 rounded-sm cursor-pointer whitespace-nowrap hover:text-yellow-500"
											@click="userRequestAccess(shop)">{{ $t('requestAccess') }}</span>
									</td>
								</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
// import SliderImage from './component/SliderImage'
import debounce from "lodash.debounce"

export default {
	name: 'customer-dashboard',
	// components : { SliderImage },
	data() {
		return {
			tab: 1,
			grid: true,
			loading: true,
			nonConnectSearch: '',
			connectedSearch: '',
			IMAGE_URL: 'https://api.b2bportalen.se/media/',
			from: null
		}
	},
	computed: {
		...mapGetters({
			GET_CONNECTED_SHOP_LIST: 'customer/GET_CONNECTED_SHOP_LIST',
			GET_NON_CONNECTED_SHOP_LIST: 'customer/GET_NON_CONNECTED_SHOP_LIST'
		})
	},
	watch: {
		from: function (newV, oldV) {
			if (newV.name === 'login') {
				this.$store.commit('customer/CLEAR_CART')
			}
		}
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.from = from
		})
	},
	mounted() {
		this.getConnectedShopList()
		this.getNonConnectedShopList()
		this.getProvider()
		window.Bus.$emit('start-buyer-dashboard-tour')

		window.localStorage.removeItem('orderDetails')
	},
	methods: {
		getConnectedShopList() {
			this.loading = true
			let url = `?is_connected=True`
			this.$store.dispatch('customer/getConnectedShopList', {data: url})
				.then(_ => {
					this.loading = false
				})
				.catch(err => {
					this.loading = false
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_CUS', null)
						window.Bus.$emit('sign-out')
					}
				})
		},
		getNonConnectedShopList() {
			this.loading = true
			let url = `?is_connected=False`
			this.$store.dispatch('customer/getNonConnectedShopList', {data: url})
				.then(_ => {
					this.loading = false
				})
				.catch(err => {
					this.loading = false
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_CUS', null)
						window.Bus.$emit('sign-out')
					}
				})
		},
		getProvider() {
			this.$store.dispatch('customer/getIntegrationProvider')
				.then(_ => {
					this.loading = false
				})
				.catch(_ => {
					this.loading = false
				})
		},
		setCurrentCompany: function (company) {
			this.$store.commit('customer/UPDATE_CURRENT_COMPANY', {data: company})
			this.$router.replace({name: 'bloglist', params: {company_id: company.id}})
		},
		userRequestAccess(company) {
			const payload = {
				company_id: company.id
			}
			this.$store.dispatch('customer/requestAccessShopowner', payload)
				.then(res => {
					this.$services.helpers.notification(res.data.message, 'success', this)
				})
				.catch(err => {
					this.$services.helpers.notification(err.response.data.message, 'error', this)
				})
		},
		switchFilter() {
			this.grid = false
		},
		switchGrid() {
			this.grid = true
		},
		openTab(num) {
			this.tab = num
		},
		searchConnectedCompanies: debounce(function () {
			let url = `?is_connected=True`
			if (this.connectedSearch) {
				url += `&search=${this.connectedSearch}`
			}
			this.$store.dispatch('customer/getConnectedShopList', {data: url})
				.then(_ => {
					this.loading = false
				})
				.catch(err => {
					this.loading = false
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_CUS', null)
						window.Bus.$emit('sign-out')
					}
				})
		}, 500),
		searchNonConnectedCompanies: debounce(function () {
			let url = `?is_connected=False`
			if (this.nonConnectSearch) {
				url += `&search=${this.nonConnectSearch}`
			}
			this.$store.dispatch('customer/getNonConnectedShopList', {data: url})
				.then(_ => {
					this.loading = false
				})
				.catch(err => {
					this.loading = false
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_CUS', null)
						window.Bus.$emit('sign-out')
					}
				})
		}, 500),
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.bg-custom-theme-blue {
	background: #8AACDE !important;
}

.bg-theme-pink {
	background: #FEF1F5;
	border: 0.5px solid $theme-primary-color;
}

.bg-theme-blue {
	background: #303DB7;
	border: 0.5px solid #303DB7;
}

.bg-theme-yellow {
	background: #F1D132;
	border: 0.5px solid #F1D132;
}

.bg-theme-green {
	background: #12CA5B;
	border: 0.5px solid #12CA5B;
}

.text-tertiary {
	color: $theme-tertiary-color !important;
}

.text-secondary {
	color: $theme-secondary-color !important;
}

.text-color-theme {
	color: $theme-primary-color !important;
}

.bg-custom-theme-teal {
	background: #85CED2 !important;
}

.bg-custom-theme-green {
	background: #BBE5AC !important;
}

.bg-custom-theme-gray {
	background-color: #fff !important;
}

.unset-opa-increa-size {
	opacity: unset !important;
	font-size: 1.3rem;
}

.card-text-num {
	color: #7E3469 !important;
	font-weight: 800;
	float: right;
}

.card-header {
	height: unset !important;
}

.unset-pad {
	padding: unset !important;
}

.non-connected .table tbody tr > td {
	padding: 1rem 0.55rem !important;
	// font-size: 12px !important;
	// text-align: left;
	// color: #6F6F6F;
}
</style>
